.color-card {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: -1px 4px 40px 5px rgba(0, 0, 0, 0.06);
  display: flex;
  max-width: 480px;
  min-height: 276px;
  padding-bottom: 32px;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 28px;
  position: relative;
  transition: all 0.2s ease;
  width: 100%;
  flex-direction: column;

  .is-mobile & {
    padding-bottom: 28px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }

  &:after {
    background-color: #ffffff;
    border-radius: 4.5px;
    bottom: -8px;
    box-shadow: -1px 4px 20px -2px rgba(22, 55, 115, 0.05);
    content: "";
    height: 100%;
    left: 12px;
    position: absolute;
    right: 12px;
    z-index: -1;
  }

  &__top {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;

    .is-mobile & {
      margin-bottom: 20px;
    }
  }

  &__actions {
    .is-loading & {
      display: none;
    }
  }

  &__bottom {
    .label-chip {
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }

    /* Bottom actions */
    &__actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  /* Invalid */
  &__invalid {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    i {
      color: #ef233c;
      font-size: 60px;
      line-height: 1;
      margin-bottom: 12px;
    }

    span {
      font-weight: 600;
      font-size: 12px;
    }
  }
}

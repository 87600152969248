// Logo brand styles
.logo-brand {
  display: flex;
  margin-bottom: 40px;
  align-items: center;
  flex-direction: column;

  &--logo {
    margin-bottom: 20px;
  }
}
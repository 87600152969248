html {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
  color: #000000;
  font-size: 1em;
  line-height: 1.8;
}

label {
  font-weight: 500;
  letter-spacing: -0.2px;
}

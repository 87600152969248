// Search input styles
.search-input {
  display: flex;
  position: relative;
  width: 100%;

  &__prefix {
    left: 18px;
    position: absolute;
    top: 10px;
    z-index: 99999;
  }

  &__form-control {
    padding-right: 72px;
    position: relative;
    width: 100%;
    z-index: 9999;
    padding-left: 29px !important;
  }

  &__btn {
    bottom: 0;
    margin-bottom: auto;
    margin-top: auto;
    position: absolute;
    right: 10px;
    top: 0;
    z-index: 9999;
  }

  &--hero {
    margin-bottom: 12px;
  }

  &--color-picker {
    left: 0;
    position: absolute;
    top: 0;
    top: calc(var(--input-height-default) + 20px);
    width: 100%;
    z-index: 999;
  }

  &--overlay {
    background-color: transparent;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  .sketch-picker {
    border: 0 solid var(--form-control-border-color);
    border-radius: 5px !important;
    box-shadow: -1px 4px 40px 5px rgba(0, 0, 0, 0.06) !important;
    box-sizing: border-box !important;
    margin-left: auto;
    margin-right: auto;
    padding: 16px !important;
    width: 260px !important;

    .flexbox-fix {
      border-top: 0 !important;
    }

    .flexbox-fix:nth-child(3) {
      display: none !important;
    }
  }
}

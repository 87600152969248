// Form control styles
.form-control {
  display: inline-flex;
  padding-right: 16px;
  padding-left: 16px;
  height: var(--input-height-default);
  align-items: center;
  appearance: none;
  border: 2px solid var(--form-control-border-color);
  border-radius: 5px;
  outline: none;
  color: var(--form-control-text-color);
  font-weight: var(--form-control-text-weight);
  transition: box-shadow 0.2s ease;

  &:focus {
    // box-shadow: 0px 0px 0px 2px rgba(#000, 0.3);
  }

  .dark-mode & {
    background-color: #3d3d48;
  }
}

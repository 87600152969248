.color-chip {
  display: flex;
  align-items: center;

  &__preview {
    width: 60px;
    height: 60px;
    background-color: #000000;
    border-radius: 4px;
    transition: background-color 0.2s ease;

    .is-mobile & {
      width: 48px;
      height: 48px;
    }

    .color-chip--sm & {
      width: 36px;
      height: 36px;
    }

    .is-loading & {
      color: #ececec !important;
      background-color: #ececec !important;
    }
  }

  &__data {
    display: flex;
    padding-left: 16px;
    flex-direction: column;
    justify-content: center;

    &__title {
      margin-bottom: 4px;
      font-size: var(--font-size-lg);
      text-transform: capitalize;
      cursor: pointer;

      .is-mobile & {
        font-size: var(--font-size-lg);
      }

      .color-chip--sm & {
        margin-bottom: 2px;
        font-size: var(--font-size-md);
      }

      .is-loading & {
        width: 130px;
        height: 16px;
        overflow: hidden;
        line-height: 80px;
        background: #ebebec;
        background-image: linear-gradient(to right, #ebebec 0%, #e0e0e2 20%, #ebebec 40%, #ebebec 100%);
        background-repeat: no-repeat;
        border-radius: 2px;
        animation: placeHolderSwept 1s infinite linear forwards;
      }
    }

    &__label {
      display: inline-flex;
      align-items: center;
      font-size: var(--font-size-sm);
      font-weight: 600;
      line-height: 1;
      cursor: help;
      user-select: none;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;

      i {
        margin-left: 4px;
      }

      .is-loading & {
        display: block;
        height: 12px;
        overflow: hidden;
        line-height: 80px;
        background: #ebebec;
        background-image: linear-gradient(to right, #ebebec 0%, #e0e0e2 20%, #ebebec 40%, #ebebec 100%);
        background-repeat: no-repeat;
        border-radius: 2px;
        animation: placeHolderSwept 1s infinite linear forwards;
      }
    }
  }

  &__action {
    margin-left: auto;
  }
}

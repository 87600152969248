@import "./reboot";
@import "./variables";
@import "./design-system/typography";
@import "./design-system/form-control";
@import "./design-system/button";

body {
  background-color: #f7faff;

  &.dark-mode {
    background-color: #2e2e35;
  }
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  min-height: 95vh;
  padding-bottom: 15vh;
  padding-left: 20px;
  padding-right: 20px;
}

.mr-8 {
  margin-right: 8px;
}

::-moz-selection {
  background: #0d51ff;
  color: #ffffff;
}

::selection {
  background: #0d51ff;
  color: #ffffff;
}

.noselect {
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@-webkit-keyframes placeHolderSwept {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}
@-moz-keyframes placeHolderSwept {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}
@-o-keyframes placeHolderSwept {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}
@keyframes placeHolderSwept {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

.sortable-ghost {
  opacity: 0;
}

.toast-message {
  background-color: #000000;
  border-radius: 4px;
  color: #ffffff;
  cursor: default;
  font-size: 12px;
  margin-left: 20px;
  margin-top: 20px;
  font-weight: 600;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 4px;
  transform: translate3d(0, 0, 0);
  transition: transform 220ms cubic-bezier(0.2, 0, 0, 1), opacity 220ms;
}

:root {
  --input-height-default: 48px;

  --font-size-xs: 10px;
  --font-size-sm: 12px;
  --font-size-md: 14px;
  --font-size-lg: 18px;
  --font-size-xl: 20px;

  --form-control-text-color: #000000;
  --form-control-border-color: #000000;
  --form-control-text-weight: 500;

  --sidebar-width: 320px;

  .dark-mode {
    --form-control-text-color: #ffffff;
    --form-control-border-color: #3d3d48;
  }
}

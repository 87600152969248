.label-chip {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .is-legacy-mobile & {
    width: 100%;
    overflow: hidden;
    overflow:hidden;
    justify-content: flex-start;
    text-overflow: ellipsis;
    white-space: nowrap;
    // display: grid;
  }

  &__label {
    padding-top: 4px;
    padding-right: 12px;
    padding-bottom: 4px;
    padding-left: 12px;
    min-width: 80px;
    font-size: var(--font-size-xs);
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    background-color: rgba(#000000, 0.08);
    border-radius: 4px;

    .is-mobile & {
      padding-top: 2px;
      padding-right: 8px;
      padding-bottom: 2px;
      padding-left: 8px;
      margin-right: 8px;
      min-width: auto;
      // display: none;
    }

    .is-loading & {
      height: 16px;
      overflow: hidden;
      line-height: 80px;
      background: #ebebec;
      background-image: linear-gradient(to right, #ebebec 0%, #e0e0e2 20%, #ebebec 40%, #ebebec 100%);
      background-repeat: no-repeat;
      border-radius: 2px;
      animation: placeHolderSwept 1s infinite linear forwards;
    }
  }

  &__value {
    font-size: var(--font-size-md);
    font-weight: 500;
    cursor: pointer;

    .is-loading & {
      height: 16px;
      overflow: hidden;
      line-height: 80px;
      background: #ebebec;
      background-image: linear-gradient(to right, #ebebec 0%, #e0e0e2 20%, #ebebec 40%, #ebebec 100%);
      background-repeat: no-repeat;
      border-radius: 2px;
      animation: placeHolderSwept 1s infinite linear forwards;
    }
  }
}

.navigation {
  display: flex;
  justify-content: flex-end;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 99999999;

  /* Fixed */
  &.is-fixed {
    position: fixed;
  }

  /* Sidebar toggle button */
  .sidebar__toggle {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 9999;
  }

  /* Backdrop */
  .feedback__backdrop {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;

    &:before {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: var(--color-N0);
      background-color: hsla(0, 0%, 100%, 0.8);
      bottom: 0;
      content: "";
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }
  }

  /* Feedback */
  .feedback-wrapper {
    display: flex;
    position: absolute;
    top: 20px;

    /* Button */
    &__button {
      background-color: transparent;
      border: 2px solid #000;
      color: #000000;

      &:hover {
        background-color: #000;
        border-color: #000;
        color: #fff;
      }
    }

    /* Form */
    .feedback__form {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: -1px 4px 40px 5px rgba(0, 0, 0, 0.06);
      display: flex;
      flex-direction: column;
      padding: 20px;
      position: absolute;
      right: 0;
      top: 40px;
      width: 300px;

      @media screen and (max-width: 480px) {
        left: 30px;
        position: fixed;
        right: 30px;
        top: 60px;
        width: auto;
      }

      label {
        font-size: 12px;
        margin-bottom: 8px;
        // text-transform: uppercase;
        font-weight: 600;
      }

      textarea {
        border: 1px solid rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        font-size: 14px;
        height: 100px;
        line-height: 1.6;
        margin-bottom: 12px;
        max-width: 100%;
        min-width: 100%;
        padding: 16px;
        transition: all 0.2s ease;

        &:focus {
          border-color: #000;
          box-shadow: 0px 0px 0px 1px #000;
          outline: none;
        }
      }
    }
  }
}

.footer {
  .container {
    display: flex;
    padding-top: 28px;
    padding-bottom: 28px;
    overflow: hidden;
    justify-content: center;
    font-size: var(--font-size-sm);
    font-weight: 500;
    text-align: center;
    user-select: none;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    a {
      color: #000000;
      font-weight: 600;
      text-decoration: none;

      &:hover {
        color: #0d51ff;
      }
    }
  }
}

.sidebar {
  position: relative;
  z-index: 9999999;
  // position: fixed;
  // top: 0;
  // right: 0;
  // bottom: 0;
  // width: var(--sidebar-width);
  // z-index: 9999;
  // transform: translateX(100%);

  &.sidebar--is-open {
    // transform: translateX(-100%);
  }

  /* Content */
  &__content {
    background-color: #ffffff;
    bottom: 0;
    box-shadow: -1px 4px 40px 5px rgb(233, 236, 242);
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 0;
    // border-left: 1px solid #ececee;
    transform: translateX(var(--sidebar-width));
    transition: transform 0.2s ease;
    width: var(--sidebar-width);
    z-index: 9999;

    .sidebar--is-open & {
      transform: translateX(0);
    }
  }

  /* Backdrop */
  &__backdrop {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;

    &:before {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: var(--color-N0);
      background-color: hsla(0, 0%, 100%, 0.8);
      bottom: 0;
      content: "";
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }
  }

  /* Header */
  &__header {
    background-color: #ffffff;
    border-bottom: 1px solid #ececee;
    padding-top: 40px;

    -webkit-touch-callout: none;
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  /* Pin */
  &__pin {
    opacity: 1;
    position: absolute;
    right: 20px;
    top: 20px;

    &:hover {
      opacity: 0.2;
    }
  }

  /* Heading */
  &__heading {
    margin-bottom: 20px;
    padding-left: 24px;
    padding-right: 24px;
  }

  /* Tab heading */
  &__tab-heading {
    display: flex;

    button {
      align-items: center;
      appearance: none;
      background-color: transparent;
      border: none;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      display: inline-flex;
      font-size: var(--font-size-sm);
      font-weight: 700;
      justify-content: center;
      line-height: 1;
      outline: none;
      padding: 0;
      padding-bottom: 8px;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 12px;
      text-transform: uppercase;
      transition: all 0.2s ease;

      /* Hover effect */
      &:hover {
        border-color: #000000;
      }

      /* Icon */
      i {
        font-size: var(--font-size-lg);
      }

      &.active {
        border-color: #0d51ff;
      }
    }
  }

  /* Body */
  &__body {
    background-color: #fbfbfb;
    height: calc(100vh - 120px);
    overflow-y: auto;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
  }

  /* Colors list */
  &--colors-list {
    display: grid;

    // row-gap: 12px;
  }

  /* Code */
  &--code {
    font-size: 13px;

    pre {
      background-color: transparent !important;
      margin: 0;
      padding: 0 !important;
    }
  }

  /* Placeholder */
  &__placeholder {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 20%;

    &__title {
      margin-top: 20px;
      opacity: 0.2;
    }

    &__sub-title {
      font-size: var(--font-size-sm);
      font-weight: 500;
      margin-top: 4px;
      opacity: 0.3;
      text-align: center;
    }
  }

  /* Color chip */
  .color-chip-wrapper {
    border-radius: 5px;
    cursor: pointer;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
    transition: all 0.2s ease;

    .color-chip__action {
      display: none;

      @media screen and (max-width: 1024px) {
        display: flex;
        opacity: 0.1;
      }
    }

    /* Hover / Active effect */
    &:hover,
    &.is-active {
      background-color: #ffffff;
      box-shadow: -1px 4px 40px 5px rgba(0, 0, 0, 0.06);

      .color-chip__action {
        display: flex;
        opacity: 1;
      }
    }
  }
}

.button {
  display: inline-flex;
  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: var(--font-size-sm);
  font-weight: 600;
  letter-spacing: -0.2px;
  appearance: none;
  background-color: #000000;
  border: 2px solid transparent;
  border-radius: 4px;
  outline: none;
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &__icon {
    font-size: var(--font-size-md);
    & + span {
      margin-left: 4px;
    }
  }

  &__text {
    position: relative;
    top: 0;
  }

  &__wide {
    width: 100%;
  }

  &:hover {
    color: #000000;
    background-color: #ffffff;
    border-color: #000000;
  }

  /* Small button */
  &--sm {
    padding-top: 0;
    padding-right: 8px;
    padding-bottom: 0;
    padding-left: 8px;
    height: 28px;
  }

  /* Extra small button */
  &--xs {
    padding-top: 0;
    padding-right: 4px;
    padding-bottom: 0;
    padding-left: 4px;
    height: 20px;
    font-size: 10px;
  }

  /* ReadOnly */
  &--read-only {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000;

    &:hover {
      color: #ffffff;
      background-color: #000000;
      border-color: #000000;
    }
  }

  /* IconOnly */
  &--icon-only {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    color: #000000;
    background-color: transparent;
    border-color: transparent;

    &:hover {
      color: #000000;
      background-color: transparent;
      border-color: transparent;
    }
  }

  /* Primary color */
  &--primary {
    color: #ffffff;
    background-color: #0d51ff;

    &:hover {
      color: #ffffff;
      background-color: #0d51ff;
      border-color: #0d51ff;
    }
  }

  /* Pin */
  &--pin {
    &:hover {
      color: #ffffff;
      background-color: #000000;
      border-color: #000000;
    }

    &:active {
      color: #ffffff;
      background-color: #0d51ff;
      border-color: #0d51ff;
    }
  }
}

.hint {
  display: inline-flex;
  align-items: center;
  user-select: none;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &--icon {
    margin-right: 8px;
  }

  &--label {
    position: relative;
    top: -2px;
    font-size: var(--font-size-sm);
  }
}